import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Box } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import guyInSweaterLookingRight from "@/assets/guy-in-sweater-looking-right.png";
import { Colors } from "@/theme/colors";
import { ContainedButton } from "@/lib/components/contained-button";

type QuestionnaireQuizResultNoHearingLossProp = {
  handleRetakeQuiz: () => void;
  handleClose: () => void;
};

export const QuestionnaireQuizResultNoHearingLoss: FC<QuestionnaireQuizResultNoHearingLossProp> = ({ handleRetakeQuiz, handleClose }) => {
  return (
    <Grid container data-testid="questionnaire-quiz-result-no-hearing-loss" spacing={3} padding={3}>
      <Grid item sm={5} sx={{ display: { xs: "none", sm: "block" }, height: "100%" }}>
        <Box
          data-testid="questionnaire-quiz-result-no-hearing-loss-img"
          sx={{ position: "absolute", top: 0, left: 0, bottom: 0, display: { xs: "none", sm: "block" }, height: "100%", width: "40%" }}
        >
          <Image src={guyInSweaterLookingRight} layout="fill" objectFit="cover" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Grid item xs={12} pt={5}>
          <Typography
            data-testid="questionnaire-quiz-result-no-otc-title-1"
            sx={{ fontSize: 20, fontWeight: 300, color: Colors.textDarkGrey }}
          >
            Your results are in!
          </Typography>
        </Grid>
        <Grid item xs={12} pt={3}>
          <Typography
            data-testid="questionnaire-quiz-result-no-hearing-loss-subtitle"
            pt={8}
            sx={{ fontSize: { xs: 24, md: 28 }, fontWeight: 900, color: Colors.darkGreen }}
          >
            No hearing loss reported
          </Typography>
          <Typography
            data-testid="questionnaire-quiz-result-no-hearing-loss-body"
            mt={2}
            sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: 400, color: Colors.textDarkGrey }}
          >
            If you’d like to confirm your hearing loss results, answer a few more questions, grab your earphones and take our online hearing
            screener.
          </Typography>
        </Grid>

        <Grid container spacing={1} mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: { xs: "flex-start", md: "center" } }}>
          <Grid item xs={12} md={6} pb={2}>
            <a href="https://www.shoeboxonline.com/truhearing1/?" target="_blank" style={{ textDecoration: "none" }} rel="noreferrer">
              <ContainedButton
                variant="contained"
                data-testid="questionnaire-quiz-result-no-hearing-loss-browse"
                color="success"
                sx={{ paddingY: "13px", paddingX: { xs: "21px", md: "27px" }, borderRadius: 10, width: "100%" }}
              >
                <Typography sx={{ fontSize: { xs: 16, md: 20 }, fontWeight: 400, lineHeight: "19.2px" }}>
                  Take an online screening
                </Typography>
              </ContainedButton>
            </a>
          </Grid>
          <Grid item xs={12} md={6} data-testid="questionnaire-quiz-result-no-hearing-loss-learn-more-link">
            <Link onClick={handleClose} href="/partners" passHref>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 400,
                  lineHeight: "19.2px",
                  paddingLeft: { xs: 0, md: 2 },

                  color: Colors.blue,
                }}
              >
                Browse OTC products
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={{ xs: 2, md: 5 }} data-testid="questionnaire-quiz-result-no-hearing-loss-help">
          <Typography mt={2} sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: 400, color: Colors.textDarkGrey }}>
            To get more info about your hearing care options and eligible hearing aid benefits,{" "}
            <a href="tel:1-833-312-1272" style={{ color: Colors.blue, fontWeight: 900 }}>
              call 1-833-312-1272
            </a>
            .
          </Typography>
        </Grid>
        <Grid item xs={12} pt={{ xs: 2, md: 5 }}>
          <MuiLink
            data-testid="questionnaire-quiz-result-no-hearing-loss-retake"
            component="button"
            sx={{ color: Colors.blue, textDecorationColor: Colors.blue }}
            onClick={handleRetakeQuiz}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: "19.2px" }}>Retake the questionnaire</Typography>
          </MuiLink>
        </Grid>
      </Grid>
    </Grid>
  );
};
