/* istanbul ignore file */

import { echoAxios, nextjsAxios } from "@/lib/axios";

export type Answer = {
  answer: string;
  answerScore?: number | null;
};
export type Question = {
  version: string;
  questionNumber: number;
  question: string;
  answers: Answer[];
  isInternal?: boolean | null;
  scoreType?: string | null;
};

export type Questions = Question[];

export type SubmitAnswerAnswerProps = {
  questionNumber: string;
  question: string;
  answer?: string | null;
  answerScore?: number | null;
  scoreType?: string | null;
};
export type SubmitAnswersProps = {
  customerId?: string;
  version?: string;
  partnerId?: number;
  questions: SubmitAnswerAnswerProps[];
};

/* eslint-disable @typescript-eslint/naming-convention */
export enum RECOMMENDATIONS {
  "OTC" = "OTC",
  "NO_OTC" = "NO_OTC",
  "NO_HEARING_LOSS" = "NO_HEARING_LOSS",
}
/* eslint-enable @typescript-eslint/naming-convention */

export type SurveyResults = {
  id: string;
  score: {
    hearingAnswered: number;
    hearingScore: number;
    behavioralAnswered: number;
    behavioralScore: number;
  };
  recommendation: string;
};

export function getEchoQuestions(version: string) {
  return echoAxios.get<Questions>("/otc/questionnaires", { params: { version } });
}
export function getQuestions(version: string) {
  return nextjsAxios.get<Questions>("/api/questionnaires", { params: { version } });
}

export function submitEchoAnswers(submitAnswersProps: SubmitAnswersProps) {
  return echoAxios.post<SurveyResults>("/otc/surveys", submitAnswersProps);
}
export function submitAnswers(submitAnswersProps: SubmitAnswersProps) {
  return nextjsAxios.post<SurveyResults>("/api/surveys", submitAnswersProps);
}
