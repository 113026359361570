import { AxiosFileHandler } from "../axios-file-handler";
import { MiddlewareInterface } from "./middleware-interface";

export const FileHandler: MiddlewareInterface = axiosInstance => {
  axiosInstance.interceptors.response.use(
    response => {
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition && contentDisposition.includes("attachment")) {
        return new AxiosFileHandler(response);
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
