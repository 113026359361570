import { camelizeKeys, decamelize, decamelizeKeys } from "humps";
import { AxiosError } from "axios";
import { MiddlewareInterface } from "./middleware-interface";

export const CaseConverter: MiddlewareInterface = axiosInstance => {
  if (process.env.JEST_WORKER_ID) {
    return axiosInstance;
  }

  axiosInstance.interceptors.response.use(
    response => {
      const contentType = response.headers["content-type"] as undefined | string;
      if (contentType?.match(/application\/json/i)) {
        response.data = camelizeKeys(response.data);
      }
      return response;
    },
    (error: AxiosError) => {
      const contentType = error.response?.headers["content-type"] as undefined | string;
      if (error.response && contentType?.match(/application\/json/i)) {
        error.response.data = camelizeKeys(error.response.data);
      }
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(request => {
    request.params = decamelizeKeys(request.params);
    const requestData = request.data;
    if (typeof requestData === "string") {
      return request;
    } else if (requestData instanceof FormData || requestData instanceof URLSearchParams) {
      for (const key of requestData.keys()) {
        const snakeCaseKey = decamelize(key);
        if (snakeCaseKey !== key) {
          if (requestData instanceof FormData) {
            requestData.set(snakeCaseKey, requestData.get(key) as FormDataEntryValue);
          } else {
            requestData.set(snakeCaseKey, requestData.get(key) as string);
          }
          requestData.delete(key);
        }
      }
    } else {
      request.data = decamelizeKeys(requestData);
    }

    return request;
  });

  return axiosInstance;
};
