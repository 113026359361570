import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Image from "next/legacy/image";
import Link from "next/link";
import guyLookingRightThumbnail from "@/assets/guy-looking-right-thumbnail.png";
import { Colors } from "@/theme/colors";
import { ContainedButton } from "@/lib/components/contained-button";

type QuestionnaireTakeQuizProp = {
  handleTakingQuiz: () => void;
};

export const QuestionnaireTakeQuiz: FC<QuestionnaireTakeQuizProp> = ({ handleTakingQuiz }) => {
  return (
    <Grid container spacing={3} p={3}>
      <Grid data-testid="questionnaire-home-img" item xs={12} sm={5}>
        <Image src={guyLookingRightThumbnail} width={358} height={281} />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Typography data-testid="questionnaire-home-title" sx={{ fontSize: { xs: 24, md: 42 }, fontWeight: 900, color: Colors.darkGreen }}>
          Are over-the-counter (OTC) hearing aids right for you?
        </Typography>
        <Typography data-testid="questionnaire-home-subtitle" mt={2} sx={{ fontSize: 18, fontWeight: 400, color: Colors.textDarkGrey }}>
          Take TruHearing&apos;s online quiz to identify your hearing needs and determine what hearing solution may be right for you.
        </Typography>

        <Grid
          container
          spacing={1}
          mt={2}
          sx={{ flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "flex-start", md: "center" } }}
        >
          <Grid item xs={12} md={6} pb={2}>
            <ContainedButton
              variant="contained"
              data-testid="questionnaire-take-a-quiz"
              color="success"
              sx={{ paddingY: "13px", paddingX: "53px", borderRadius: 10, width: "100%" }}
              onClick={handleTakingQuiz}
            >
              <Typography sx={{ fontSize: 20, fontWeight: 400, lineHeight: "19.2px" }}>Take the Quiz</Typography>
            </ContainedButton>
          </Grid>
          <Grid item xs={12} md={6} data-testid="questionnaire-learn-more-link">
            <Link href="/support" passHref>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 400,
                  lineHeight: "19.2px",
                  paddingLeft: { xs: 0, md: 2 },
                  cursor: "pointer",
                }}
              >
                Learn more
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
