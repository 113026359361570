import { AxiosError } from "axios";
import { BugsnagClient } from "@/lib/bugsnag";
import { EchoApiError } from "@/lib/axios/errors/echo-api-error";
import { MiddlewareInterface } from "./middleware-interface";

export const ErrorHandler: MiddlewareInterface = axiosInstance => {
  axiosInstance.interceptors.response.use(
    response => {
      BugsnagClient.leaveBreadcrumb("XMLHttpRequest succeeded", {
        requestId: response.headers["x-request-id"],
        status: response.status,
        request: `${response.config.method} ${response.config.url}`,
      });
      return response;
    },
    (error: AxiosError) => {
      if (error.response) {
        const { response } = error;
        BugsnagClient.leaveBreadcrumb("Failed XMLHttpRequest", {
          requestId: response.headers["x-request-id"],
          status: response.status,
          request: `${response.config.method} ${response.config.url}`,
        });

        if (error.status && (error.status >= 500 || error.status === 408)) {
          BugsnagClient.notify(error);
        }
        const message = error.response.statusText || "Internal server error";
        const statusCode = error.response.status || 500;
        const description = error.response.data ? JSON.stringify(error.response.data) : "";
        return Promise.reject(new EchoApiError(message, statusCode, description));
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
