import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Box } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import coupleOnCouch from "@/assets/couple-on-couch.png";
import { Colors } from "@/theme/colors";
import { ContainedButton } from "@/lib/components/contained-button";

type QuestionnaireQuizResultNoOtcProp = {
  handleRetakeQuiz: () => void;
};

export const QuestionnaireQuizResultNoOtc: FC<QuestionnaireQuizResultNoOtcProp> = ({ handleRetakeQuiz }) => {
  return (
    <Grid container data-testid="questionnaire-quiz-result-no-otc" spacing={3} padding={3}>
      <Grid item sm={5} sx={{ display: { xs: "none", sm: "block" }, height: "100%" }}>
        <Box
          data-testid="questionnaire-quiz-result-no-otc-img"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            display: { xs: "none", sm: "block" },
            height: "100%",
            width: "40%",
          }}
        >
          <Image src={coupleOnCouch} layout="fill" objectFit="cover" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Grid item xs={12} pt={5}>
          <Typography
            data-testid="questionnaire-quiz-result-no-otc-title-1"
            sx={{ fontSize: 20, fontWeight: 300, color: Colors.textDarkGrey }}
          >
            Your results are in!
          </Typography>
        </Grid>
        <Grid item xs={12} pt={3}>
          <Typography
            data-testid="questionnaire-quiz-result-no-otc-subtitle"
            sx={{ fontSize: { xs: 24, md: 28 }, fontWeight: 900, color: Colors.darkGreen }}
          >
            Your best option is prescription hearing aids.
          </Typography>
          <Typography
            data-testid="questionnaire-quiz-result-no-otc-body"
            mt={2}
            sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: 400, color: Colors.textDarkGrey }}
          >
            Based on your results, prescription hearing aids are the right fit for you. Prescription hearing aids give you the best in
            hearing technology and are programmed and personalized to your exact needs based on a hearing exam by a hearing healthcare
            professional.
          </Typography>
        </Grid>

        <Grid container spacing={1} mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: { xs: "flex-start", md: "center" } }}>
          <Grid item xs={12} md={6} pb={2}>
            <a href="tel:1-833-312-1272" style={{ color: Colors.white, textDecoration: "none" }}>
              <ContainedButton
                variant="contained"
                data-testid="questionnaire-quiz-result-no-otc-browse"
                color="success"
                sx={{ paddingY: "13px", paddingX: { xs: "25px", md: "27px" }, borderRadius: 10, width: "100%" }}
              >
                <Typography sx={{ fontSize: { xs: 16, md: 20 }, fontWeight: 400, lineHeight: { xs: "15.36px", md: "19.2px" } }}>
                  Schedule a Consultation
                </Typography>
              </ContainedButton>
            </a>
          </Grid>
          <Grid item xs={12} md={6} data-testid="questionnaire-quiz-result-no-otc-learn-more-link">
            <Link href="/support" passHref>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 400,
                  lineHeight: "19.2px",
                  paddingLeft: { xs: 0, md: 2 },

                  color: Colors.blue,
                  cursor: "pointer",
                }}
              >
                Learn more
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={{ xs: 2, md: 5 }} data-testid="questionnaire-quiz-result-no-otc-help">
          <Typography mt={2} sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: 400, color: Colors.textDarkGrey }}>
            To get more info about your hearing care options and eligible hearing aid benefits,{" "}
            <a href="tel:1-833-312-1272" style={{ color: Colors.blue, fontWeight: 900 }}>
              call 1-833-312-1272
            </a>
            .
          </Typography>
        </Grid>
        <Grid item xs={12} pt={{ xs: 2, md: 5 }}>
          <MuiLink
            data-testid="questionnaire-quiz-result-no-otc-retake"
            component="button"
            sx={{ color: Colors.blue, textDecorationColor: Colors.blue }}
            onClick={handleRetakeQuiz}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: "19.2px" }}>Retake the questionnaire</Typography>
          </MuiLink>
        </Grid>
      </Grid>
    </Grid>
  );
};
