/* istanbul ignore file */

import axios from "axios";
import { Authorization } from "./middleware/authorization";
import { FileHandler } from "./middleware/file-handler";
import { CaseConverter } from "./middleware/case-converter";
import { ErrorHandler } from "./middleware/error-handler";

export function createAxiosClient(baseUrl: string, addCaseConverter = true) {
  let instance = axios.create({
    baseURL: baseUrl || "", // eslint-disable-line @typescript-eslint/naming-convention
  });
  if (addCaseConverter) {
    instance = CaseConverter(instance);
  }

  /**
   * Axios runs middleware in reverse order for requests and uses the same order as defined for response transformation
   * Therefore, CaseConverter must be the first middleware so it changes casing last for requests and response
   * casing first.
   */
  return ErrorHandler(Authorization(FileHandler(instance)));
}

export const echoAxios = createAxiosClient(process.env.ECHO_API_URL as string);

let nextJsBaseUrl = "";
if (typeof window !== "undefined") {
  const parsedUrl = new URL(`${window.location}`);
  nextJsBaseUrl = parsedUrl.origin;
}
export const nextjsAxios = createAxiosClient(nextJsBaseUrl, false);
