import { AxiosResponse } from "axios";
import { FileHandlerError } from "./errors/file-handler-error";

export class AxiosFileHandler<T, D> implements AxiosResponse<T, D> {
  constructor(private axiosResponse: AxiosResponse<T, D>) {}

  get data() {
    return this.axiosResponse.data;
  }

  set data(d) {
    this.axiosResponse.data = d;
  }

  get status() {
    return this.axiosResponse.status;
  }

  set status(s) {
    this.axiosResponse.status = s;
  }

  get statusText() {
    return this.axiosResponse.statusText;
  }

  set statusText(st) {
    this.axiosResponse.statusText = st;
  }

  get headers() {
    return this.axiosResponse.headers;
  }

  set headers(h) {
    this.axiosResponse.headers = h;
  }

  get config() {
    return this.axiosResponse.config;
  }

  set config(c) {
    this.axiosResponse.config = c;
  }

  download(overrideFilename?: string) {
    let element: HTMLAnchorElement | null = null;
    try {
      let file = this.axiosResponse.request.response;
      if (typeof file === "string") {
        file = new Blob([file]);
      }
      element = document.createElement("a");
      element.href = URL.createObjectURL(file);

      // attempt to grab the filename from the response. If no filename use system default.
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(this.axiosResponse.headers["content-disposition"]);
      if (overrideFilename == null && matches != null && matches[1]) {
        let filename = matches[1].replace(/['"]/g, ""); // using regex to find filename in the content-disposition response header.
        filename = filename.trim().replace(/[^\w\d_\-.]/g, ""); // remove special characters and trim whitespaces.
        element.download = filename;
      }

      if (overrideFilename) {
        element.download = overrideFilename;
      }

      if (overrideFilename == null && matches == null) {
        throw new FileHandlerError("Error AxiosFileHandler::download(): Filename not found. Check API or provide a custom filename.");
      }

      document.body.appendChild(element);
      element.click();
    } catch (e: unknown) {
      if (e instanceof Error) {
        throw new FileHandlerError(e.message);
      }
      throw new FileHandlerError("Error: AxiosFileHandler on download()");
    } finally {
      if (element) {
        document.body.removeChild(element);
      }
    }
  }
}
