import Button, { ButtonProps } from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";

export const OutlinedButton = styled(Button)<ButtonProps>(() => ({
  border: "2px solid #006EA8",
  borderRadius: 25,
  textTransform: "none",
  "&:hover": {
    border: "2px solid #005785",
    boxShadow: `0px 4px 10px ${alpha("#000", 0.1)}`,
  },
}));
