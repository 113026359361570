export class EchoApiError extends Error {
  response?: boolean; // required for testing
  status: number;
  description: string;

  constructor(message: string, status = 500, description = "") {
    super(message);
    this.status = status;
    this.description = description;
  }
}
