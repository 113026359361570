import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { Box } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import guyLookingRight from "@/assets/guy-looking-right.png";
import { Colors } from "@/theme/colors";
import { ContainedButton } from "@/lib/components/contained-button";

type QuestionnaireQuizResultOtcProp = {
  handleRetakeQuiz: () => void;
  handleClose: () => void;
};

export const QuestionnaireQuizResultOtc: FC<QuestionnaireQuizResultOtcProp> = ({ handleRetakeQuiz, handleClose }) => {
  return (
    <Grid container data-testid="questionnaire-quiz-result-otc" spacing={3} padding={{ xs: 1, md: 3 }}>
      <Grid item sm={5} sx={{ display: { xs: "none", sm: "block" }, height: "100%" }}>
        <Box
          data-testid="questionnaire-quiz-result-otc-img"
          sx={{ position: "absolute", top: 0, left: 0, bottom: 0, display: { xs: "none", sm: "block" }, height: "100%", width: "40%" }}
        >
          <Image src={guyLookingRight} layout="fill" objectFit="cover" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Grid item xs={12} pt={5}>
          <Typography
            data-testid="questionnaire-quiz-result-otc-title-1"
            sx={{ fontSize: 20, fontWeight: 300, color: Colors.textDarkGrey }}
          >
            Your results are in!
          </Typography>
        </Grid>
        <Grid item xs={12} pt={3}>
          <Typography
            data-testid="questionnaire-quiz-result-otc-subtitle"
            sx={{ fontSize: { xs: 24, md: 28 }, fontWeight: 900, color: Colors.darkGreen }}
          >
            OTC hearing aids may be a good fit for you.
          </Typography>
          <Typography
            data-testid="questionnaire-quiz-result-otc-body"
            mt={2}
            sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: 400, color: Colors.textDarkGrey }}
          >
            Based on your answers and preferences, your hearing loss is considered mild to moderate—meaning OTC hearing aids may be a good
            option for you. TruHearing offers top brands of OTC hearing aids at discounted prices, however, your hearing benefits may not
            apply.
          </Typography>
        </Grid>

        <Grid container spacing={1} mt={2} sx={{ display: "flex", flexDirection: "row", alignItems: { xs: "flex-start", sm: "center" } }}>
          <Grid item xs={12} sm={6}>
            <ContainedButton
              href="/partners"
              variant="contained"
              data-testid="questionnaire-quiz-result-otc-browse"
              color="success"
              sx={{ paddingY: "13px", paddingX: { xs: "25px", md: "27px" }, borderRadius: 10, width: "100%" }}
              onClick={handleClose}
            >
              <Typography
                sx={{ fontSize: { xs: 16, md: 20 }, fontWeight: 400, lineHeight: { xs: "15.36px", md: "19.2px" }, whiteSpace: "nowrap" }}
              >
                Browse Products
              </Typography>
            </ContainedButton>
          </Grid>
          <Grid item xs={12} sm={6} data-testid="questionnaire-quiz-result-otc-learn-more-link">
            <Link href="/support" passHref>
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 20 },
                  fontWeight: 400,
                  lineHeight: "19.2px",
                  paddingLeft: { xs: 0, sm: 2 },

                  color: Colors.blue,
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Learn more
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={{ xs: 2, md: 5 }} data-testid="questionnaire-quiz-result-otc-help">
          <Typography mt={2} sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: 400, color: Colors.textDarkGrey }}>
            Call us to learn about your options including using your benefits for prescription hearing aids,{" "}
            <a href="tel:1-833-312-1272" style={{ color: Colors.blue, fontWeight: 900 }}>
              call 1-833-312-1272
            </a>
            .
          </Typography>
        </Grid>
        <Grid item xs={12} pt={{ xs: 2, md: 5 }}>
          <MuiLink
            data-testid="questionnaire-quiz-result-otc-retake"
            component="button"
            sx={{ color: Colors.blue, textDecorationColor: Colors.blue }}
            onClick={handleRetakeQuiz}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: "19.2px" }}>Retake the questionnaire</Typography>
          </MuiLink>
        </Grid>
      </Grid>
    </Grid>
  );
};
