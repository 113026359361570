import React, { FC, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { setSkippedQuestionnaire } from "@/redux/user";
import { QuestionnaireLoader } from "@/features/questionnaire/questionnaire-loader";
import { QuestionnaireTakeQuiz } from "@/features/questionnaire/questionnaire-take-quiz";
import { useAppDispatch } from "@/lib/redux-hooks";

interface QuestionnaireDialogProps {
  skipTakeQuizQuestion?: boolean;
}

export const QuestionnaireDialog: FC<QuestionnaireDialogProps> = ({ skipTakeQuizQuestion }) => {
  const dispatch = useAppDispatch();
  const [takingQuestionnaire, setTakingQuestionnaire] = useState(skipTakeQuizQuestion === true);

  const handleTakingQuiz = () => {
    setTakingQuestionnaire(true);
  };
  const handleClose = () => {
    dispatch(setSkippedQuestionnaire(true));
  };

  return (
    <Dialog
      open
      maxWidth="lg"
      sx={{ marginBottom: 6 }}
      PaperProps={{
        style: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 40,
          borderBottomLeftRadius: 40,
          borderBottomRightRadius: 20,
        },
      }}
    >
      <DialogContent sx={{ position: "relative", padding: !takingQuestionnaire ? "15px" : "45px" }}>
        <div data-testid="questionnaire-dialog"></div>
        <IconButton data-testid="questionnaire-dialog-close" onClick={handleClose} sx={{ position: "absolute", top: 5, right: 5 }}>
          <CloseIcon />
        </IconButton>

        {!takingQuestionnaire && <QuestionnaireTakeQuiz handleTakingQuiz={handleTakingQuiz} />}
        {takingQuestionnaire && <QuestionnaireLoader version="1.0" />}
      </DialogContent>
    </Dialog>
  );
};
