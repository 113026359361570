import React, { FC } from "react";
import { RECOMMENDATIONS } from "@/lib/api/questionnaire";
import { QuestionnaireQuizResultNoHearingLoss } from "@/features/questionnaire/questionnaire-quiz-result-no-hearing-loss";
import { QuestionnaireQuizResultOtc } from "@/features/questionnaire/questionnaire-quiz-result-otc";
import { QuestionnaireQuizResultNoOtc } from "@/features/questionnaire/questionnaire-quiz-result-no-otc";

type QuestionnaireQuizResultProp = {
  handleRetakeQuiz: () => void;
  handleClose: () => void;
  recommendation: RECOMMENDATIONS;
};

export const QuestionnaireQuizResult: FC<QuestionnaireQuizResultProp> = ({ handleRetakeQuiz, handleClose, recommendation }) => {
  switch (recommendation) {
    case RECOMMENDATIONS.OTC:
      return <QuestionnaireQuizResultOtc handleRetakeQuiz={handleRetakeQuiz} handleClose={handleClose} />;
    case RECOMMENDATIONS.NO_OTC:
      return <QuestionnaireQuizResultNoOtc handleRetakeQuiz={handleRetakeQuiz} />;
    case RECOMMENDATIONS.NO_HEARING_LOSS:
      return <QuestionnaireQuizResultNoHearingLoss handleRetakeQuiz={handleRetakeQuiz} handleClose={handleClose} />;
  }
};
