import { MiddlewareInterface } from "./middleware-interface";

export const Authorization: MiddlewareInterface = axiosInstance => {
  axiosInstance.interceptors.request.use(request => {
    if (process.env.OTC_SHARED_SECRET) {
      // one of the axios mocks seems to be messing with the headers object
      request.headers?.set("X-OTC-TOKEN", process.env.OTC_SHARED_SECRET);
    }

    return request;
  });
  return axiosInstance;
};
