import React, { FC, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircleIcon from "@mui/icons-material/Circle";
import Alert from "@mui/material/Alert";
import Image from "next/image";
import { Colors } from "@/theme/colors";
import { Answer, Question, RECOMMENDATIONS, submitAnswers, SubmitAnswersProps } from "@/lib/api/questionnaire";
import { QuestionnaireProgress } from "@/features/questionnaire/questionnaire-progress";
import wind from "@/assets/wind.svg";
import whisper from "@/assets/whisper.svg";
import umbrella from "@/assets/umbrella.svg";
import car from "@/assets/car.svg";
import chatBubble from "@/assets/chat-bubble.svg";
import { getSecondaryDisplayNameId } from "@/redux/partner";
import { setSkippedQuestionnaire, setSurveyId } from "@/redux/user";
import { QuestionnaireQuizResult } from "@/features/questionnaire/questionnaire-quiz-result";
import { ContainedButton } from "@/lib/components/contained-button";
import { OutlinedButton } from "@/lib/components/outlined-button";
import { useAppDispatch, useAppSelector } from "@/lib/redux-hooks";
import { EchoApiError } from "@/lib/axios/errors/echo-api-error";

type QuestionnaireProp = {
  version: string;
  questions: Question[];
};

export const Questionnaire: FC<QuestionnaireProp> = ({ version, questions }) => {
  const dispatch = useAppDispatch();
  const partnerId = useAppSelector(getSecondaryDisplayNameId);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [hoverAnswerIndex, setHoverAnswerIndex] = useState<number | null>(null);
  const [errorText, setErrorText] = useState("");
  const [recommendation, setRecommendation] = useState<RECOMMENDATIONS | null>(null);

  const question = questions.find(q => q.questionNumber === questionNumber) as Question;

  const selectedIndex: Answer | undefined = answers[question.questionNumber];

  const handleFinish = async () => {
    const submitAnswersProps: SubmitAnswersProps = {
      version,
      partnerId: partnerId,
      questions: [],
    };
    questions.forEach(question => {
      const answer = answers[question.questionNumber];
      submitAnswersProps.questions.push({
        questionNumber: question.questionNumber.toString(),
        question: question.question,
        answer: answer?.answer === undefined ? null : answer.answer,
        answerScore: answer?.answerScore === undefined ? null : answer.answerScore,
        scoreType: question.scoreType,
      });
    });

    try {
      setErrorText("");
      const results = await submitAnswers(submitAnswersProps);

      // Parse Recommendations
      if (results.data.recommendation === "OTC") {
        dispatch(setSurveyId(results.data.id));
        setRecommendation(RECOMMENDATIONS.OTC);
      } else if (results.data.recommendation === "No OTC") {
        dispatch(setSurveyId(results.data.id));
        setRecommendation(RECOMMENDATIONS.NO_OTC);
      } else if (results.data.recommendation === "No hearing loss") {
        dispatch(setSurveyId(results.data.id));
        setRecommendation(RECOMMENDATIONS.NO_HEARING_LOSS);
      } else {
        // unknown recommendation
        console.error(`unknown recommendation: ${results.data.recommendation}`);
        setErrorText("Unknown error");
      }
    } catch (error: unknown) {
      if (typeof error === "string") {
        setErrorText(error);
      } else if (error instanceof EchoApiError) {
        setErrorText(error.description);
      } else if (error instanceof Error) {
        setErrorText(error.message);
      }
    }
  };

  const handleSelectIndex = (answerIndex: number) => {
    const newAnswers = [...answers];

    newAnswers[question.questionNumber] = question.answers[answerIndex];

    setAnswers(newAnswers);
  };

  const handleNext = () => {
    setQuestionNumber(questionNumber + 1);
  };
  const handleBack = () => {
    setQuestionNumber(questionNumber - 1);
  };

  const handleClose = () => {
    dispatch(setSkippedQuestionnaire(true));
  };

  const handleRetakeQuiz = () => {
    setAnswers([]);
    setQuestionNumber(1);
    setRecommendation(null);
  };
  if (recommendation) {
    return <QuestionnaireQuizResult handleClose={handleClose} handleRetakeQuiz={handleRetakeQuiz} recommendation={recommendation} />;
  }

  return (
    <Grid data-testid="questionnaire-question-wrapper" container spacing={2} justifyContent="space-between">
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <QuestionnaireProgress questions={questions} questionNumber={questionNumber} />
      </Grid>
      {errorText !== "" && (
        <Grid item xs={12}>
          <Alert
            severity="error"
            sx={{
              width: "100%",
            }}
            data-testid="submit-answers-error"
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 400,
                lineHeight: { xs: "24px", md: "22px" },
              }}
            >
              {errorText}
            </Typography>
          </Alert>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        mb={{ xs: questionNumber === questions.length && question.answers.length === 6 ? 0 : "10px", md: "50px" }}
      >
        <Typography
          data-testid="questionnaire-question"
          sx={{
            fontSize: { xs: 23, md: 42 },
            fontWeight: 900,
            color: Colors.darkGreen,
            textAlign: { xs: "left", md: "center" },
            paddingBottom: 1.5,
            lineHeight: { xs: "26px", md: "53px" },
          }}
        >
          {question.question}
        </Typography>
        {questionNumber === questions.length && question.answers.length === 6 && (
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              {question.answers.map((answer, i) => {
                if (i !== 5) {
                  return (
                    <Button
                      variant="text"
                      data-testid="questionnaire-answer-button"
                      onClick={() => handleSelectIndex(i)}
                      sx={{
                        maxWidth: { xs: 1, md: 200 },
                        minWidth: { xs: 1, md: 125 },
                        paddingX: { xs: "7px", md: 2 },
                        paddingY: { xs: "6px", md: 2 },
                        display: "flex",
                        flexDirection: {
                          xs: "row",
                          md: question.answers.length > 2 ? "column" : "row",
                        },
                        alignItems: "center",
                        justifyContent: "flex-start",
                        margin: { xs: "2.5px", md: 2 },
                        border: { xs: "1px solid #C4C4C4", md: "none" },
                        borderRadius: { xs: 10, md: 0 },
                        textTransform: "none",
                      }}
                      key={`answer_${answer.answer}`}
                      onMouseEnter={() => setHoverAnswerIndex(i)}
                      onMouseLeave={() => setHoverAnswerIndex(null)}
                    >
                      {i === 0 && (
                        <Box
                          sx={{
                            width: { xs: 50, md: 100 },
                            height: { xs: 50, md: 100 },
                            backgroundColor:
                              hoverAnswerIndex === i || selectedIndex?.answer?.toString() === answer.answer.toString()
                                ? "#00822F"
                                : Colors.disabled,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 50,
                            marginRight: { xs: 1, md: 0 },
                            padding: 1,
                          }}
                        >
                          <Image src={wind} alt="wind Icon" style={{ width: "60%", height: "60%" }} />
                        </Box>
                      )}
                      {i === 1 && (
                        <Box
                          sx={{
                            width: { xs: 50, md: 100 },
                            height: { xs: 50, md: 100 },
                            backgroundColor:
                              hoverAnswerIndex === i || selectedIndex?.answer?.toString() === answer.answer.toString()
                                ? "#00822F"
                                : Colors.disabled,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 50,
                            marginRight: { xs: 1, md: 0 },
                            padding: 1,
                          }}
                        >
                          <Image src={whisper} alt="whisper Icon" style={{ width: "60%", height: "60%" }} />
                        </Box>
                      )}
                      {i === 2 && (
                        <Box
                          sx={{
                            width: { xs: 50, md: 100 },
                            height: { xs: 50, md: 100 },
                            backgroundColor:
                              hoverAnswerIndex === i || selectedIndex?.answer?.toString() === answer.answer.toString()
                                ? "#00822F"
                                : Colors.disabled,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 50,
                            marginRight: { xs: 1, md: 0 },
                            padding: 1,
                          }}
                        >
                          <Image src={umbrella} alt="umbrella Icon" style={{ width: "60%", height: "60%" }} />
                        </Box>
                      )}
                      {i === 3 && (
                        <Box
                          sx={{
                            width: { xs: 50, md: 100 },
                            height: { xs: 50, md: 100 },
                            backgroundColor:
                              hoverAnswerIndex === i || selectedIndex?.answer?.toString() === answer.answer.toString()
                                ? "#00822F"
                                : Colors.disabled,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 100,
                            marginRight: { xs: 1, md: 0 },
                            padding: 1,
                          }}
                        >
                          <Image src={chatBubble} alt="chat bubble Icon" style={{ width: "90%", height: "90%" }} />
                        </Box>
                      )}
                      {i === 4 && (
                        <Box
                          sx={{
                            width: { xs: 50, md: 100 },
                            height: { xs: 50, md: 100 },
                            backgroundColor:
                              hoverAnswerIndex === i || selectedIndex?.answer?.toString() === answer.answer.toString()
                                ? "#00822F"
                                : Colors.disabled,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 50,
                            marginRight: { xs: 1, md: 0 },
                          }}
                        >
                          <Image src={car} alt="car Icon" style={{ width: "90%", height: "90%" }} />
                        </Box>
                      )}

                      <Grid item xs={10}>
                        <Typography
                          pt={{ xs: 0, md: 2 }}
                          sx={{
                            color: Colors.textDarkGrey,
                            fontSize: { xs: 14, md: 16 },
                            fontWeight: 400,
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          {answer.answer.split("(")[0]}
                          <Typography
                            component="span"
                            pt={{ xs: 0, md: 2 }}
                            sx={{
                              color: Colors.textDarkGrey,
                              fontSize: { xs: 14, md: 16 },
                              fontWeight: 300,
                              textAlign: { xs: "left", md: "center" },
                            }}
                          >
                            &#40;{answer.answer.split("(")[1]}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Button>
                  );
                }
              })}
            </Box>
            <Button
              onClick={() => handleSelectIndex(5)}
              data-testid="questionnaire-answer-button"
              sx={{
                width: 1,
                paddingBottom: 0,
                paddingLeft: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: { xs: "flex-start", md: "center" },
                textTransform: "none",
              }}
              onMouseEnter={() => setHoverAnswerIndex(5)}
              onMouseLeave={() => setHoverAnswerIndex(null)}
            >
              {hoverAnswerIndex === 5 || selectedIndex?.answer?.toString() === question.answers[5].answer ? (
                <CheckCircleIcon />
              ) : (
                <CircleIcon sx={{ color: Colors.disabled }} />
              )}
              <Typography px={1} sx={{ color: Colors.textDarkGrey, fontSize: 16 }}>
                {question.answers[5].answer}
              </Typography>
            </Button>
          </Box>
        )}
        {questionNumber !== questions.length && (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              width: 1,
            }}
          >
            {question.answers.map((answer, i) => {
              return (
                <Button
                  variant="contained"
                  data-testid="questionnaire-answer-button"
                  onClick={() => handleSelectIndex(i)}
                  sx={{
                    width: { xs: 1, md: 175 },
                    height: 1,
                    paddingX:
                      question.answers.length <= 2
                        ? { xs: "22px", md: "32px" }
                        : {
                            xs: "11px",
                            md: "32px",
                          },
                    paddingY:
                      question.answers.length <= 2
                        ? { xs: "16px", md: "23px" }
                        : {
                            xs: "7px",
                            md: "23px",
                          },
                    border: "1px solid #C4C4C4",
                    borderRadius: { xs: 50, md: 0 },
                    borderTopLeftRadius: { md: i === 0 ? 100 : 0 },
                    borderBottomLeftRadius: { md: i === 0 ? 100 : 0 },
                    borderTopRightRadius: { md: i === question.answers.length - 1 ? 100 : 0 },
                    borderBottomRightRadius: { md: i === question.answers.length - 1 ? 100 : 0 },
                    display: "flex",
                    flexDirection: { xs: "row", md: question.answers.length > 2 ? "column" : "row" },
                    alignItems: "center",
                    justifyContent: { xs: "flex-start", md: "center" },
                    backgroundColor: selectedIndex?.answer?.toString() === answer.answer.toString() ? "#00822F" : "#fff",
                    margin: { xs: question.answers.length > 2 ? "2.5px" : "6px", md: 0 },
                    boxShadow: "none",
                    textTransform: "none",
                  }}
                  key={`answer_${answer.answer}`}
                  onMouseEnter={() => setHoverAnswerIndex(i)}
                  onMouseLeave={() => setHoverAnswerIndex(null)}
                >
                  {/* <div> */}
                  {hoverAnswerIndex === i || selectedIndex?.answer?.toString() === answer.answer.toString() ? (
                    <CheckCircleIcon
                      sx={{
                        fontSize:
                          question.answers.length <= 2
                            ? { xs: 35, md: 54 }
                            : {
                                xs: 25,
                                md: 28,
                              },
                      }}
                    />
                  ) : (
                    <CircleIcon
                      sx={{
                        color: Colors.disabled,
                        fontSize:
                          question.answers.length <= 2
                            ? { xs: 35, md: 54 }
                            : {
                                xs: 25,
                                md: 28,
                              },
                      }}
                    />
                  )}
                  {/* </div> */}
                  <Typography
                    px={1}
                    sx={{
                      color:
                        hoverAnswerIndex === i || selectedIndex?.answer?.toString() === answer.answer.toString()
                          ? Colors.white
                          : Colors.textDarkGrey,
                      fontSize: {
                        xs: question.answers.length > 2 ? 16 : 26,
                        md: question.answers.length > 2 ? 16 : 39,
                      },
                      fontWeight: 400,
                    }}
                  >
                    {answer.answer}
                  </Typography>
                </Button>
              );
            })}
          </Box>
        )}
      </Grid>

      {questionNumber !== 1 && (
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }} pr={1}>
          <OutlinedButton
            data-testid="questionnaire-back-button"
            variant="outlined"
            color="success"
            sx={{
              borderRadius: 50,
              paddingX: { xs: "30px", md: "45px" },
              paddingY: { xs: 0.5, md: "5px" },
              fontSize: { xs: 16, md: 20 },
              fontWeight: { xs: 400, md: 700 },
              "&:disabled": { backgroundColor: Colors.disabled, color: Colors.white },
            }}
            onClick={handleBack}
          >
            Back
          </OutlinedButton>
        </Grid>
      )}
      <Grid
        item
        xs={questionNumber === 1 ? 12 : 6}
        pl={1}
        sx={{ display: "flex", justifyContent: questionNumber === 1 ? "center" : "flex-start" }}
      >
        {questionNumber !== questions.length && (
          <ContainedButton
            variant="contained"
            data-testid="questionnaire-next-button"
            color="success"
            sx={{
              borderRadius: 50,
              paddingX: { xs: "30px", md: "45px" },
              paddingY: { xs: 0.5, md: "5px" },
              fontSize: { xs: 16, md: 20 },
              fontWeight: { xs: 400, md: 700 },
              "&:disabled": { backgroundColor: Colors.disabled, color: Colors.white },
            }}
            disabled={selectedIndex === undefined}
            onClick={handleNext}
          >
            Next
          </ContainedButton>
        )}
        {questionNumber === questions.length && (
          <ContainedButton
            variant="contained"
            data-testid="questionnaire-finish-button"
            color="success"
            sx={{
              borderRadius: 50,
              paddingX: { xs: "30px", md: "45px" },
              paddingY: { xs: 0.5, md: "5px" },
              fontSize: { xs: 16, md: 20 },
              fontWeight: { xs: 400, md: 700 },
              "&:disabled": { backgroundColor: Colors.disabled, color: Colors.white },
            }}
            disabled={selectedIndex === undefined}
            onClick={handleFinish}
          >
            Finish
          </ContainedButton>
        )}
      </Grid>
    </Grid>
  );
};
