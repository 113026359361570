import React, { FC, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getQuestions, Questions } from "@/lib/api/questionnaire";
import { Questionnaire } from "@/features/questionnaire/questionnaire";

type QuestionnaireLoaderProp = {
  version: string;
};

export const QuestionnaireLoader: FC<QuestionnaireLoaderProp> = ({ version }) => {
  const [questions, setQuestions] = useState<Questions | null>(null);
  useEffect(() => {
    getQuestions(version).then(loadedQuestions => {
      setQuestions(loadedQuestions.data);
    });
  }, [version]);
  if (questions === null)
    return (
      <div data-testid="questionnaire-loader-loading">
        <CircularProgress />
      </div>
    );
  return <Questionnaire questions={questions} version={version} />;
};
