import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { Questions } from "@/lib/api/questionnaire";

type QuestionnaireProgressProp = {
  questions: Questions;
  questionNumber: number;
};

export const QuestionnaireProgress: FC<QuestionnaireProgressProp> = ({ questions, questionNumber }) => {
  const progress = (questionNumber / questions.length) * 100;
  return (
    <Grid container item md={4} justifyContent="center" alignItems="center">
      <Grid item data-testid="questionnaire-progress-text" xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          Question {questionNumber} of {questions.length}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress data-testid="questionnaire-progress-bar" variant="determinate" value={progress} sx={{ height: "8px" }} />
      </Grid>
    </Grid>
  );
};
